<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-settings" />&nbsp;Critérios
        <CButton color="success" size="sm" class="float-right" @click="salvarCriterio()">
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar
        </CButton>
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CTabs variant="pills" :fade="true" :vertical="{navs: 'col-md-2 ', content: 'col-md-10'}">
          <CTab title="Geral" active>
            <CCard>
              <CCardHeader>Materiais</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="2">
                    <CSelect
                      :options="opcoes.fck"
                      label="Concreto"
                      description="Classe do concreto"
                      :value.sync="criterio[1]"
                    ></CSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="2">
                    <CSelect
                      label="Aço"
                      :options="opcoes.fyk"
                      description="Resistência do Aço"
                      :value.sync="criterio[5]"
                    ></CSelect>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Coeficientes</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Concreto (γc)"
                      type="number"
                      description="Coeficientes de ponderação das resistências"
                      :value.sync="criterio[4]"
                    ></CInput>
                  </CCol>
                  <CCol sm="3">
                    <CInput
                      label="Aço (γy)"
                      description="Coeficientes de ponderação das resistências"
                      type="number"
                      :value.sync="criterio[6]"
                    />
                  </CCol>
                  <CCol sm="3">
                    <CInput
                      label="γf"
                      description="Coeficientes de ponderação das ações"
                      type="number"
                      :value.sync="criterio[3]"
                    />
                  </CCol>
                  <CCol sm="3">
                    <CInput
                      label="Rüsch"
                      description="Efeito Rüsch"
                      type="number"
                      :value.sync="criterio[13]"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Agressividade Ambiental</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Cobrimento (m)"
                      type="number"
                      step="0.001"
                      description="Cobrimento nominal"
                      :value.sync="criterio[2]"
                    ></CInput>
                  </CCol>
                  <CCol sm="3">
                    <CInput
                      label="Concreto magro (m)"
                      description="Lastro de concreto não estrutural"
                      type="number"
                      :value.sync="criterio[9]"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Bitolas Disponíveis</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Bitola (mm)"
                      type="number"
                      min="0.0"
                      description="Bitola em milimetros"
                      :value.sync="bitola"
                      prepend="ø"
                      @keyup.enter="adicionarBitola(bitola)"
                    >
                      <CButton slot="append" color="success" @click="adicionarBitola(bitola)">+</CButton>
                    </CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="5">
                    <CDataTable :fields="fieldAco" :items="obtenhaItensBitola()">
                      <td slot="acao" slot-scope="{item, index}">
                        <CButton @click="excluirBitola(index)" color="danger" size="sm">
                          <CIcon name="cil-trash"></CIcon>
                        </CButton>
                      </td>
                    </CDataTable>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Outros</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <b-form-group
                      label="Eixo das cargas"
                      description="Eixo a considerar das cargas, global ou local"
                    >
                      <b-form-radio-group
                        id="btn-radios-1"
                        v-model="criterio[11]"
                        :options="opcoes.eixoCargas"
                        button-variant="outline-primary"
                        buttons
                        name="radios-btn-default"
                      ></b-form-radio-group>
                    </b-form-group>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab title="Sapata">
            <CCard>
              <CCardHeader>Dimensionamento</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <b-form-group
                      label="Método de Cálculo"
                      description="Determinar o método de cálculo"
                    >
                      <b-form-radio-group
                        id="btn-calculo"
                        v-model="criterio[101]"
                        :options="opcoes.metodoCalculo"
                        button-variant="outline-primary"
                        buttons
                        name="radios-btn-default"
                      ></b-form-radio-group>
                    </b-form-group>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <b-form-group
                      label="Peso Próprio"
                      description="Determina se será levado em consideração o peso próprio"
                    >
                      <b-form-radio-group
                        id="btn-peso-proprio"
                        v-model="criterio[102]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                        name="radios-btn-default"
                      ></b-form-radio-group>
                    </b-form-group>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <b-form-group
                      label="Sapata Rígida"
                      description="Força adotar uma altura, que a sapata será considerada rígida"
                    >
                      <b-form-radio-group
                        id="btn-rigida"
                        v-model="criterio[103]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                        name="radios-btn-default"
                      ></b-form-radio-group>
                    </b-form-group>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Coeficiente Sapata Rígida"
                      description="Coeficiente para determinação de sapata rígida (Padrão 3)"
                      type="number"
                      :value.sync="criterio[105]"
                      min="1"
                    ></CInput>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Coeficientes de segurança</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      description="Relação entre o momento atuante com o momento contra tombamento"
                      label="Tombamento"
                      :value.sync="criterio[112]"
                      type="number"
                      min="1"
                      step="0.1"
                    ></CInput>
                    <CInput
                      description="Relação entre esforços horizontais e esforço contra deslizamento"
                      label="Deslizamento"
                      :value.sync="criterio[113]"
                      type="number"
                      min="1"
                      step="0.1"
                    ></CInput>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Dimensões</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup
                      description="Determina as dimensões da sapata, levando em conta as dimensões do pilar"
                    >
                      <legend
                        slot="label"
                        class="bv-no-focus-ring col-form-label pt-0"
                      >Balanceamento</legend>
                      <b-form-radio-group
                        slot="input"
                        id="btn-balanceamento"
                        v-model="criterio[108]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                      ></b-form-radio-group>
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup description="Determina o rodapé com a mesma altura da sapata">
                      <legend
                        slot="label"
                        class="bv-no-focus-ring col-form-label pt-0"
                      >Altura Constante</legend>
                      <b-form-radio-group
                        slot="input"
                        id="btn-altura-constante"
                        v-model="criterio[104]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                      ></b-form-radio-group>
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      description="Dimensão mínima da sapata em planta"
                      label="Dimensão mínima"
                      :value.sync="criterio[110]"
                      type="number"
                      min="0"
                      step="0.05"
                      append="m"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      description="Altura mínima da sapata"
                      label="Altura mínima"
                      :value.sync="criterio[115]"
                      type="number"
                      min="0"
                      step="0.05"
                      append="m"
                    />
                  </CCol>
                </CRow>
                <CRow v-if="!criterio[104]">
                  <CCol sm="3">
                    <CInput
                      description="Altura mínima rodapé"
                      label="Altura mínima do rodapé da sapata"
                      :value.sync="criterio[107]"
                      type="number"
                      min="0"
                      step="0.05"
                      append="m"
                    />
                  </CCol>
                </CRow>
                <CRow v-if="!criterio[104]">
                  <CCol sm="3">
                    <CInput
                      description="Angulo entre a linha que liga a altura da sapata e rodapé com a horizontal"
                      label="Ângulo entre a sapata e rodapé"
                      :value.sync="criterio[114]"
                      type="number"
                      min="0"
                      append="ø"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      description="Acréscimo ao pilar que facilita a montagem e o apoio do pilar"
                      label="Pilarete"
                      :value.sync="criterio[109]"
                      type="number"
                      min="0.00"
                      step="0.005"
                      append="m"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Armadura</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup description="Espaçmento mínimo e máximo da armadura em cm">
                      <label slot="label">Espaçamento</label>
                      <vue-slider
                        slot="input"
                        v-model="criterio[111]"
                        :min="0"
                        :max="30"
                        :tooltip="'active'"
                        :enable-cross="false"
                      ></vue-slider>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab title="Bloco Sobre Estacas">
            <CCard>
              <CCardHeader>Dimensionamento</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup
                      description="Força a utilização do método das bielas nos blocos que atende aos requisitos"
                    >
                      <legend
                        slot="label"
                        class="bv-no-focus-ring col-form-label pt-0"
                      >Metodo das biélas</legend>
                      <b-form-radio-group
                        slot="input"
                        id="btn-altura-constante"
                        v-model="criterio[213]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                      ></b-form-radio-group>
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup
                      description="Determina se será levado em consideração o peso próprio"
                    >
                      <legend slot="label" class="bv-no-focus-ring col-form-label pt-0">Peso Próprio</legend>
                      <b-form-radio-group
                        slot="input"
                        id="btn-altura-constante"
                        v-model="criterio[209]"
                        :options="opcoes.simNao"
                        button-variant="outline-primary"
                        buttons
                      ></b-form-radio-group>
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup description="Limite superior e inferior para o ângulo da biela">
                      <label slot="label">Ângulo das bielas</label>
                      <vue-slider
                        slot="input"
                        v-model="criterio[212]"
                        :min="0"
                        :max="90"
                        :tooltip="'active'"
                        :enable-cross="false"
                      ></vue-slider>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab title="Tubulão">
            <CCard>
              <CCardHeader>Dimensões Minímas</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Diâmetro mínimo do fuste"
                      description="Diametro mínimo"
                      :value.sync="criterio[301]"
                      append="m"
                    ></CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Altura máxima do tronco de tubulão"
                      description="Altura máxima"
                      :value.sync="criterio[302]"
                      append="m"
                    ></CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CInput
                      label="Altura do rodapé"
                      description="Altura do rodapé"
                      :value.sync="criterio[303]"
                      append="m"
                    ></CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="3">
                    <CFormGroup
                      description="Ângulo mínimo que relaciona a altura do tubulão com o diêmetro da base"
                    >
                      <label slot="label">Ângulo mínimo</label>
                      <vue-slider
                        slot="input"
                        v-model="criterio[304]"
                        :min="0"
                        :max="90"
                        :tooltip="'active'"
                        :enable-cross="false"
                      ></vue-slider>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
      <CCardFooter>
        <CButton color="success" size="sm" class="float-right" @click="salvarCriterio()">
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>
<script>
import Meta from "./../../shared/meta-dados";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Axios from "axios";
export default {
  name: "Criterio",
  components: { Loader, VueSlider },
  mixins: [mixin],
  data() {
    return {
      requisicaoAberta: false,
      criterio: {},
      opcoes: {
        fck: [
          { label: "C20", value: 20 },
          { label: "C25", value: 25 },
          { label: "C30", value: 30 },
          { label: "C35", value: 35 },
          { label: "C40", value: 40 },
          { label: "C45", value: 45 },
          { label: "C50", value: 50 }
        ],
        fyk: [
          { text: "CA50", value: 500 },
          { text: "CA60", value: 600 }
        ],
        metodoCalculo: [
          { text: "Método das bielas", value: 1 },
          { text: "CEB-70", value: 2 }
        ],
        eixoCargas: [
          { text: "Local", value: true },
          { text: "Global", value: false }
        ],
        simNao: [
          { text: "Sim", value: true },
          { text: "Não", value: false }
        ]
      },
      fieldAco: [
        { key: "bitola", label: "Bitola (mm)", _classes: "text-center" },
        { key: "area", label: "Área da seção (cm²)", _classes: "text-center" },
        { key: "acao", label: "Ação" }
      ],
      bitola: null
    };
  },
  methods: {
    obtenhaCriterio() {
      let idProjeto = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      Axios.get("/projeto/" + idProjeto + "/criterio")
        .then(resposta => {
          if (resposta.status == 200)
            this.criterio = Object.assign(
              {},
              this.criterio,
              resposta.data.retorno.dados
            );
        })
        .catch(error => {})
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    salvarCriterio() {
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.post("/projeto/" + idProjeto + "/criterio", this.criterio)
        .then(response => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Critério atualizado",
            classe: "bg-success"
          });
        })
        .catch(erro => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Não foi possível atualizar o critério",
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    adicionarBitola(bitola) {
      bitola = parseFloat(bitola);
      if (this.criterio[10].indexOf(bitola) < 0 && bitola > 0 && bitola <= 50)
        this.criterio[10].push(bitola);
      this.criterio[10].sort((a, b) => {
        return a - b;
      });
      this.bitola = "";
    },
    excluirBitola(bitola) {
      this.criterio[10].splice(bitola, 1);
    },
    obtenhaItensBitola() {
      let bitolas = [];
      if (Array.isArray(this.criterio[10])) {
        bitolas = this.criterio[10].map(val => {
          return {
            bitola: val,
            area: Math.round(Math.PI * val * val) / 4 / 100
          };
        });
      }
      return bitolas;
    }
  },
  created: function() {
    this.obtenhaCriterio();
  }
};
</script>
<style>
.c-dark-theme select option {
  background-color: rgba(255, 255, 255, 0.1);
}
.c-dark-theme .form-control > option {
  color: black;
}
</style>
